import Form from '@rjsf/material-ui'
import React, { useState, Fragment, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Button,InputLabel,Avatar, FormControl, Select, Fab,MenuItem, Accordion, AccordionSummary, AccordionDetails, CircularProgress, Tooltip, Divider, List, ListItem, ListItemText, Typography, Grid, Paper, Box, TextField, Stepper, Step, StepButton, StepContent, IconButton, Menu, Card, Chip } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import axios from 'axios';
import { AppBarHeaderDummy } from "../components/AppBarIntegrator";
import { makeStyles } from '@material-ui/core/styles';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { truncateSync } from 'fs';
import { Controlled as CodeMirror2 } from 'react-codemirror2';

import 'codemirror/theme/darcula.css';
import { act } from 'react-dom/test-utils';
import { BuildRounded,SecurityRounded, ExpandMoreRounded, OfflineBoltRounded, SettingsRounded, SpellcheckRounded, VpnKeyRounded } from '@material-ui/icons';
require('codemirror/mode/python/python')


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function(match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

Function.deserialise = function (key, data) {
    return (data instanceof Array && data[0] == 'window.Function') ?
        new (Function.bind.apply(Function, [Function].concat(data[1], [data[2]]))) :
        data
        ;
};

Function.prototype.toJSON = function () {
    var whitespace = /\s/;
    var pair = /\(\)|\[\]|\{\}/;

    var args = new Array();
    var string = this.toString();

    var fat = (new RegExp(
        '^\s*(' +
        ((this.name) ? this.name + '|' : '') +
        'function' +
        ')[^)]*\\('
    )).test(string);

    var state = 'start';
    var depth = new Array();
    var tmp;

    for (var index = 0; index < string.length; ++index) {
        var ch = string[index];

        switch (state) {
            case 'start':
                if (whitespace.test(ch) || (fat && ch != '('))
                    continue;

                if (ch == '(') {
                    state = 'arg';
                    tmp = index + 1;
                }
                else {
                    state = 'singleArg';
                    tmp = index;
                }
                break;

            case 'arg':
            case 'singleArg':
                var escaped = depth.length > 0 && depth[depth.length - 1] == '\\';
                if (escaped) {
                    depth.pop();
                    continue;
                }
                if (whitespace.test(ch))
                    continue;

                switch (ch) {
                    case '\\':
                        depth.push(ch);
                        break;

                    case ']':
                    case '}':
                    case ')':
                        if (depth.length > 0) {
                            if (pair.test(depth[depth.length - 1] + ch))
                                depth.pop();
                            continue;
                        }
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        state = (fat) ? 'body' : 'arrow';
                        break;

                    case ',':
                        if (depth.length > 0)
                            continue;
                        if (state == 'singleArg')
                            throw '';
                        args.push(string.substring(tmp, index).trim());
                        tmp = index + 1;
                        break;

                    case '>':
                        if (depth.length > 0)
                            continue;
                        if (string[index - 1] != '=')
                            continue;
                        if (state == 'arg')
                            throw '';
                        args.push(string.substring(tmp, index - 1).trim());
                        state = 'body';
                        break;

                    case '{':
                    case '[':
                    case '(':
                        if (
                            depth.length < 1 ||
                            !(depth[depth.length - 1] == '"' || depth[depth.length - 1] == '\'')
                        )
                            depth.push(ch);
                        break;

                    case '"':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '"')
                            depth.pop();
                        break;
                    case '\'':
                        if (depth.length < 1)
                            depth.push(ch);
                        else if (depth[depth.length - 1] == '\'')
                            depth.pop();
                        break;
                }
                break;

            case 'arrow':
                if (whitespace.test(ch))
                    continue;
                if (ch != '=')
                    throw '';
                if (string[++index] != '>')
                    throw '';
                state = 'body';
                break;

            case 'body':
                if (whitespace.test(ch))
                    continue;
                string = string.substring(index);

                if (ch == '{')
                    string = string.replace(/^{\s*(.*)\s*}\s*$/, '$1');
                else
                    string = 'return ' + string.trim();

                index = string.length;
                break;

            default:
                throw '';
        }
    }

    return ['window.Function', args, string];
};

const useStyles = makeStyles((theme) => ({
    root: {
       display: 'flex',
        paddingRight: '12px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.default,
        paddingRight: 20,
        marginTop: 50
    },
    fab: {
        '& > *': {
            margin: theme.spacing(1),

        },
        position: 'fixed',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9
    },
    signinbutton: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        justifyContent: 'center',
        width: '90%',
        backgroundColor: 'white',
        color: '#0069ea',
        borderRadius: '4px',
        border: '2px solid #0069ea',
        height: '50px',
        marginBottom: '20px',
        cursor: 'pointer'
      },
}));
const borderlines = ["lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen","lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen","lightcoral", "blue", "green", "indigo","gold", "fuchsia", "lightblue", "lightgreen"]
const ObjectFieldTemplate = ({ TitleField, properties, title, description }, props) => {
 
    return (
      <div>
        <TitleField title={title} />
        <div className="row">
          {properties.map(prop => (
            <div
              className="col-lg-2 col-md-4 col-sm-6 col-xs-12"
              key={prop.content.key}>
              {prop.content}
            </div>
          ))}
        </div>
        {description}
      </div>
    );
  }
const ArrayFieldTemplate = (props)=> {
  
   function getTitle(){
   // console.log('formcontext:', props.formContext)
   // console.log('prps:', props)
   const body = props.formContext.filter(body => body.name === 'body')[0]
   const arrays = body && body.schema.properties ? body.schema.properties.filter(body => body.schema.items) : []
  // console.log('body === ', body.schema.properties)
  // console.log('arrays ==== ', arrays)
   // const title_obj = arrays.filter(data =>  data.schema.items.properties.includes(props.formData))
   // console.log('title obj === ', title_obj)
   let arrayFilter = props.formData.map(itemY => { return itemY; });
   // console.log('arrayFilter::: ', arrayFilter)
    let compareTwoArrayOfObjects = (
        first_array_of_objects,
        second_array_of_objects
    ) => {
        return (
            first_array_of_objects.length === second_array_of_objects.length &&
            first_array_of_objects.every((element_1) =>
                second_array_of_objects.some(
                    (element_2) =>
                        element_1.key === element_2.key 
                )
            )
        );
    };
   // Use filter and "not" includes to filter the full dataset by the filter dataset's val.
   let filteredX = arrays.filter(array => array.schema.items.properties && array.schema.items.properties.length === arrayFilter.length && compareTwoArrayOfObjects(arrayFilter,array.schema.items.properties) === true);
  
   // Print the result.
  // console.log('title OBJ:: ',filteredX);
    const new_title = filteredX[0]?.title ? filteredX[0].title : "Body"
    return new_title
   }

    return (
        <div style={{border: '1px solid black', borderRadius: '5px', padding: '8px', margin: '8px'}}>
        <Typography variant="h5">{ props.title ?? props.schema.title}</Typography>
        <Typography>{props.schema.description}</Typography>
        {props.items &&
          props.items.map((element, index) => (
            <div key={element.key} className={element.className} style={{border: '2px solid', borderRadius: '5px', borderColor: borderlines[index], padding: '8px', margin: "8px 0px 8px 0px"}}>
              <div>{element.children}</div>
              <div style={{float: "right"}}>
              {element.hasMoveDown && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index + 1
                  )}>
                  <ArrowDownwardRoundedIcon />
                </IconButton>
              )}
              {element.hasMoveUp && (
                  <IconButton  style={{cursor: "pointer", backgroundColor: 'white', color: 'black', padding: '8px 12px 8px 12px'}}
                  onClick={element.onReorderClick(
                    element.index,
                    element.index - 1
                  )}>
                  <ArrowUpwardRoundedIcon />
                </IconButton>
              )}
              <Tooltip title={"Delete"}>
             <IconButton style={{cursor: "pointer", backgroundColor: 'white', color: borderlines[index], padding: '8px 12px 8px 12px', border: '1px solid',borderColor: borderlines[index], borderRadius: '5px', marginBottom: '8px'}}
                onClick={element.onDropIndexClick(element.index)}>
                <ClearRoundedIcon />
              </IconButton></Tooltip>
            </div>
            <hr style={{marginTop: '8px', marginBottom: '8px', width: "100%"}} />
            </div>
          ))}
  
        {props.canAdd  && (
          <div className="row">
            <p className="col-xs-3 col-xs-offset-9 array-item-add text-right">
              <Button onClick={props.onAddClick} type="button" variant="contained" style={{textTransform: "none", color: "white", backgroundColor: "black", float: "right", padding: '4px 8px 4px 8px', margin: '12px 0px 12px 0px'}}>
                + Add to {props.title === 'properties' ? getTitle() : props.title ?? props.schema.title} 
              </Button>
            </p>
          </div>
        )}
      </div>
    );
  }
  const Authorizer = (app) => {
    const classes = useStyles();
    console.log('app is: ', app)
    const [auths, setAuths] = useState('');
    const [credentialId, setCredentialId] = useState()
    const [newauth, setNewAuth] = useState();
    const [name, setName] = useState();


    useEffect(() => {
        handleLoad();
         console.log('new auth hit from useEffect')
        // console.log('auths are: ', auths)
        
         console.log('number of auths: ',number);
        if (auths.length > 0) {
          var number = auths.length - 1
          
          console.log('newauth now exists')
          setAuths(auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null)
          // node.data.authname = auths ? auths.slice(-1)[0].app + " - " + auths.slice(-1)[0].name + '#' + number + 1 : null;
          console.log('newauth 68: ', auths ? auths[number].app + " - " + auths[number].name + '#' + number + 1 : null)
         
          // saveNode(node);
        }
      }, [])

      const handleNewAuth = (data, authname) => {
        var config = {
          method: 'get',
          url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?user=' + app.user + '&app=' + app.app.appName,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        axios(config)
          .then(function (response) {
            // console.log(response.data);
            setAuths(response.data);
            
            // dispatch(credentialid({ item: data, value: authname }));
            // node.data.credential = data;
            // sendData(node.data.credential)
           // console.log('newauth 249: ', authname)
            setNewAuth(authname);
            
            // console.log('setAuths was hit')
            // node.data.authname = authname;
            //sendAuth(authname);
            // setAuthName(authname);
            // saveNode(node);
            setName(response.data.name)
            
          })
          .catch(function (error) {
            console.log(error);
          });
    
      }
      
      const handleLoad = () => {
      
        var config = {
          method: 'get',
          url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?user=' + app.user + '&appSlug=' + app.app.appSlug ,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        axios(config)
          .then(function (response) {
            console.log('auths is: ', response.data);
            console.log('auths lengths: ', response.data.length)
           app.user === null ? setAuths([]) : setAuths(response.data);
      
          }, [auths], console.log('auths are: ', auths))
      
          .catch(function (error) {
            console.log(error);
          });
      };
      let windowObjectReference = null;
      let previousUrl = null;
      const receiveMessage = event => {
        // Do we trust the sender of this message? (might be
        // different from what we originally opened, for example).
        /* if (event.origin !== 'https://ec2-35-81-172-203.us-west-2.compute.amazonaws.com') {
          return;
        } */
        const { data } = event;
        // console.log( document.cookie.replace(/(?:(?:^|.*;s*)token*=s*([^;]*).*$)|^.*$/, '$1') )
        // console.log('popup window data is: '+data);
       // console.log('newauth 195 is: '+JSON.stringify(data))
      
       // sendData(data)
        
        //set the selected auth connection to the newly created connection
        var config = {
          method: 'get',
          url: 'https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?tenant='+ app.tenant +'&id=' + data,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        axios(config)
          .then(function (response) {
    
            setNewAuth(response.data.oid);
            // console.log(response.data)
           // console.log('newauth 209 is: '+JSON.stringify(response.data.id))
           
            var authname = response.data[0].app + " - " + response.data[0].name;
            handleNewAuth(data, authname)
          }, [newauth])
    
          .catch(function (error) {
            console.log(error);
          });
    
         // console.log('newauth is: '+JSON.stringify(newauth))
        // setAuths(newauth);
        // if we trust the sender and the source is our popup
        /* if (data.source === 'success') {
          // get the URL params and redirect to our server to use Passport to auth/login
          const { payload } = data;
         const redirectUrl = `/auth/google/login${payload}`;
          window.location.pathname = redirectUrl;
        } */
    
      };
    
      const addAuth = (url, name) => {
        if (!app.user) {
          console.log('user is empty')
         // sendLoggedOutToAuthorizer(true)
        }
        else {
          // remove any existing event listeners
          window.removeEventListener('message', receiveMessage);
    
          // window features
          const strWindowFeatures =
            'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
    
          if (windowObjectReference === null || windowObjectReference.closed) {
            /* if the pointer to the window object in memory does not exist
             or if such pointer exists but the window was closed */
            windowObjectReference = window.open(url, name, strWindowFeatures);
          } else if (previousUrl !== url) {
            /* if the resource to load is different,
             then we load it in the already opened secondary window and then
             we bring such window back on top/in front of its parent window. */
            windowObjectReference = window.open(url, name, strWindowFeatures);
            windowObjectReference.focus();
          } else {
            /* else the window reference must exist and the window
             is not closed; therefore, we can bring it back on top of any other
             window with the focus() method. There would be no need to re-create
             the window or to reload the referenced resource. */
            windowObjectReference.focus();
          }
    
          // add the listener for receiving a message from the popup
          window.addEventListener('message', event => receiveMessage(event), false);
    
          // assign the previous URL
          previousUrl = url;
        }
      };
    return(<>
      {auths.length === 0 ?<><br/><br/> <button size="small" id='new-auth-button' labelId='new-auth-button-label'
                            className={classes.signinbutton} 
                            variant="contained"

                            onClick={() => {
                               // console.log('https://editor.workload.co:5000/auth/' + app.app.appSlug + '?uid=' + app.user + '&t=' + app.tenant); 
                                addAuth('https://editor.workload.co:5000/auth/' + app.app.appSlug + '?uid=' + app.user + '&t=' + app.tenant)
                            }}
                            startIcon={<SecurityRounded />}><Avatar style={{ left: 110, position: 'absolute', width: '24px', height:'24px' }} src={app.app.iconURL} /><Typography variant='h6' style={{ color: '#0069ea', fontWeight: 'bold', fontSize:  '16px' }} >Sign in to {app.app.appName}</Typography></button> 
                            </>
                            : 
                        <>
                          <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel id="demo-simple-select-outlined-label">{app.app.appName} account</InputLabel>
        
                            <Select
                              id='trigger-authorize'
                              style={{ width: '100%' }}
                              labelId="auth-select-outlined-label"
                              defaultValue=""
                              //value={auth}
                              //onChange={handleChange}
                              label={app.app.appName +" account"}
                            >
                              {auths && auths.length > 0 ? auths.map((item, i) => <MenuItem onClick={() => setCredentialId(item._id.$oid)} id={item.id} key={i} value={`${item.app}` + ' - ' + `${item.name}` + '#' + i + 1}><Typography style={{fontWeight: 'bold', fontSize: '20px'}} >{item.app} - {item.name} #{i + 1}</Typography></MenuItem>) :
                                <MenuItem id='nodata' key='nodata' disabled>+ Add a new connection</MenuItem>} </Select></FormControl> </>
                                } 
                                 <SecurityRounded style={{ marginLeft: '5px', color: 'gray', height: '15px', float: 'left', display: 'block' }} /><Typography style={{ fontSize: '12px', color: 'gray' }}>{app.app.appName} is a secure partner with Workload. <a style={{ color: '#0069ea' }} href="https://workload.co/privacy" target="_blank">Learn more about encryption and storage.</a> </Typography>
                            <br/><br/>
        </>
    )
  }

const AuthInfoForm = ( {actions, authInfo, updateAuthInfo, appInfo }) => {
//console.log('authinfoform actions: ', actions)
    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0)

    const [apiType, setApiType] = useState(authInfo.apiType)

    console.log('this is the friggin auth info here....:',authInfo)

    const apiTypeOptions = [
        {
            "key": "basic",
            "label": "Basic"
        },
        {
            "key": "oauth2",
            "label": "OAuth2"
        },
        {
            "key": "apikey",
            "label": "API Key/Custom"
        },
        {
            "key": "customCode",
            "label": "Custom Code"
        },
        {
            "key": "",
            "label": ""
        }
    ]

    const basicSetupConfig = {
        "steps": [
            {
                "title": "Username/password form",
                "key": "basicFormFields",
                "description": "The form presented to the user is a simple username and password, all that's needed for Basic Auth",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.basicFormFields ?? [
                    {
                        "key": "username"
                    },
                    {
                        "key": "password"
                    }
                ]
            },
            {
                "key": "testAuth",
                "title": "Auth Test Action",
                "value": authInfo.testAuth ?? "",
                "description": "Define an action that can validate the user auth input (a GET request with no params works best)",
                "schema": {
                    "type": "string",
                    "enum": Object.keys(actions),
                    "title": "Auth Test Action",
                    "description": "The action that will generate a test with the user input to make sure they are connected and authenticated."
                }
               
            }
        ]
    }

    const apikeySetupConfig = {
        "steps": [
            {
                "key": "apikeyFormFields",
                "title": "Form Fields",
                "description": "Define a list of fields for the user to fill out",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.apikeyFormFields ?? []
            },
            {
                "key": "requestConfig",
                "title": "Define Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.requestConfig ?? {
                    url: "",
                    params: [],
                    headers: []
                }
            },
            {
                "key": "testAuth",
                "title": "Auth Test Action",
                "value": authInfo.testAuth ?? "",
                "description": "Define an action that can validate the user auth input (a GET request with no params works best)",
                "schema": {
                    "type": "string",
                    "enum": Object.keys(actions),
                    "title": "Auth Test Action",
                    "description": "The action that will generate a test with the user input to make sure they are connected and authenticated."
                }
               
            }
        ]
    }


    const oauthSetupConfig = {
        "steps": [
            {
                "key": "oauthFormFields",
                "title": "Form Fields",
                "description": "Define a list of fields for the user to fill out",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.oauthFormFields ?? []
            },
            {
                "key": "clientInfo",
                "title": "Application Credentials",
                "description": "Input the client_id and client_secret here",
                "schema": {
                    "type": "object",
                    "properties": {
                        "client_id": {
                            "type": "string"
                        },
                        "client_secret": {
                            "type": "string"
                        }
                    }
                },
                "value": authInfo.clientInfo ?? {
                    client_id: "",
                    client_secret: ""
                }
            },
            {
                "key": "authRequestConfig",
                "title": "Define Authorization Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "url": {
                            "title": "Request URL",
                            "type": "string"
                        },
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "body": {
                            "title": "Form Body",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.authRequestConfig ?? {
                    url: "",
                    params: [
                        {
                            "key": "client_id",
                            "value": "${clientInfo.client_id}"
                        }, {
                            "key": "redirect_uri",
                            "value": "https://editor.workload.co:5000/auth/"+appInfo.appSlug+"/redirect"
                        }, {
                            "key": "response_type",
                            "value": "code"
                        }, {
                            "key": "state",
                            "value": "state"
                        }
                    ],
                    headers: [],
                    body: []
                }
            },
            {
                "key": "accessRequestConfig",
                "title": "Define Access Token Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "url": {
                            "title": "Request URL",
                            "type": "string"
                        },
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "body": {
                            "title": "Form Body",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.accessRequestConfig ?? {
                    url: "",
                    params: [],
                    headers: [],
                    body: [
                        {
                            "key": "client_id",
                            "value": "${clientInfo.client_id}"
                        }, {
                            "key": "client_secret",
                            "value": "${clientInfo.client_secret}"
                        }, {
                            "key": "grant_type",
                            "value": "authorization_code"
                        }, {
                            "key": "redirect_uri",
                            "value": "https://editor.workload.co:5000/auth/"+appInfo.appSlug+"/redirect"
                        }, {
                            "key": "code",
                            "value": "code"
                        }
                    ]
                }
            },
            {
                "key": "refreshRequestConfig",
                "title": "Define Refresh Token Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "url": {
                            "title": "Request URL",
                            "type": "string"
                        },
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "body": {
                            "title": "Form Body",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.refreshRequestConfig ?? {
                    "url": "",
                    "params": [],
                    "headers": [],
                    "body": [
                        {
                            "key": "client_id",
                            "value": "${clientInfo.client_id}"
                        },
                        {
                            "key": "client_secret",
                            "value": "${clientInfo.client_secret}"
                        },
                        {
                            "key": "refresh_token",
                            "value": "${formFields.refresh_token}"
                        },
                        {
                            "key": "grant_type",
                            "value": "refresh_token"
                        }
                    ]
                }
            },
            {
                "key": "requestConfig",
                "title": "Define Request",
                "description": "Define where in the request the previously defined fields will go",
                "schema": {
                    "type": "object",
                    "properties": {
                        "params": {
                            "title": "Query Parameters",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        },
                        "headers": {
                            "title": "Headers",
                            "type": "array",
                            "items": {
                                "type": "object",
                                "properties": {
                                    "key": {
                                        "type": "string"
                                    },
                                    "value": {
                                        "type": "string"
                                    }
                                }
                            }
                        }
                    }
                },
                "value": authInfo.requestConfig ?? {
                    url: "",
                    params: [],
                    headers: []
                }
            }
        ]
    }

    var defaultCustomCode = `
    domain = r['domain']
    access_token = r['access_token']
    url = url.format(domain=domain)
    headers['APIKEY'] = access_token
    `

    var starterCustomCode = `
    import sys, os,json, re, base64, datetime, hashlib, hmac 
    import requests

    def authenticatedRequest(creds, method, url, headers={}, params={}, body={}):
        credURL = "https://7nx4ewphyg.execute-api.us-west-2.amazonaws.com/production/docdb/credentials?id="+creds
        credInfo = requests.get(credURL).json()[0]
    `

    var enderCustomCode = `
        r = requests.request(method, url, headers=headers, params=params, json=body)
    `

    const customCodeConfig = {
        'steps': [
            {
                "key": "customCodeFormFields",
                "title": "Form Fields",
                "description": "Define a list of fields for the user to fill out",
                "schema": {
                    "type": "array",
                    "items": {
                        "type": "object",
                        "properties": {
                            "key": {
                                "type": "string"
                            },
                            "title": {
                                "type": "string"
                            },
                            "description": {
                                "type": "string"
                            }
                        }
                    }
                },
                "value": authInfo.customCodeFormFields ?? []
            },
            {
                "title": "Define Auth Request",
                "key": "customCodeInfo",
                "description": "",
                "schema": {
                    "type": "object",
                    "properties": {
                        "customCode": {
                            "type": "string",
                            "code": true
                        }
                    }
                },
                "value": authInfo.customCodeInfo ?? { customCode: defaultCustomCode }
            },
            {
                "key": "testAuth",
                "title": "Auth Test Action",
                "value": authInfo.testAuth ?? "",
                "description": "Define an action that can validate the user auth input (a GET request with no params works best)",
                "schema": {
                    "type": "string",
                    "enum": Object.keys(actions),
                    "title": "Auth Test Action",
                    "description": "The action that will generate a test with the user input to make sure they are connected and authenticated."
                }
               
            }
        ]
    }

    const [expanded, setExpanded] = React.useState(activeStep);
    const history = useHistory()
    const handleStepAccordionChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
    const [alert, setAlert] = useState();
    const [saving, setSaving] = React.useState()
    const saveToDB = () => {
        console.log(appInfo)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(appInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); setSaving(false); history.push("/develop/"+appInfo.appSlug+"/authentication/edit") })
    }
    const user = localStorage.getItem('user')
    const tenant = localStorage.getItem('tenant')


    return (
        <>
            <Stepper nonLinear activeStep={activeStep} orientation='vertical'>
                {
                    authInfo.apiType == "basic" ?
                        basicSetupConfig.steps.map((step, j) => {
                            return (
                                <Accordion onChange={handleStepAccordionChange(j)} style={{padding: '12px'}}>
                                    <AccordionSummary expandIcon={<ExpandMoreRounded />} onClick={() => setActiveStep(j)}><Chip size="small" color="primary" style={{marginRight: '10px'}} label={"Step "+(j+1)} /><Typography style={{fontWeight: 700}}>{step.title}</Typography></AccordionSummary>
                                    <AccordionDetails style={{display: 'block'}}>
                                        <Typography>{step.description}</Typography>
                                        <Form ArrayFieldTemplate={ArrayFieldTemplate} ObjectFieldTemplate={ObjectFieldTemplate} schema={step.schema} formData={step.value} onChange={e => {
                                            updateAuthInfo(step.key, e.formData)
                                        }}><Fragment /></Form>
                                         <Button onClick={saveToDB} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                        :
                        null
                }
                {
                    authInfo.apiType == "oauth2" ?
                        oauthSetupConfig.steps.map((step, j) => {
                            return (
                                
                                <Accordion onChange={handleStepAccordionChange(j)} style={{padding: '12px'}}>
                                    <AccordionSummary expandIcon={<ExpandMoreRounded />} onClick={() => setActiveStep(j)}><Chip size="small" color="primary" style={{marginRight: '10px'}} label={"Step "+(j+1)} /><Typography style={{fontWeight: 700}}>{step.title}</Typography></AccordionSummary>
                                    <AccordionDetails style={{display: 'block'}}>
                                        <Typography>{step.description}</Typography>
                                        <Form ArrayFieldTemplate={ArrayFieldTemplate} ObjectFieldTemplate={ObjectFieldTemplate} schema={step.schema} formData={step.value} onChange={e => {
                                            updateAuthInfo(step.key, e.formData)
                                        }}><Fragment /></Form>
                                          <Button onClick={saveToDB} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) 
                       
                    
                        :
                        null
                }
                {
                    authInfo.apiType == "apikey" ?
                        apikeySetupConfig.steps.map((step, j) => {
                            return (
                                <Accordion onChange={handleStepAccordionChange(j)} style={{padding: '12px'}}>
                                <AccordionSummary expandIcon={<ExpandMoreRounded />} onClick={() => setActiveStep(j)}><Chip size="small" color="primary" style={{marginRight: '10px'}} label={"Step "+(j+1)} /><Typography style={{fontWeight: 700}}>{step.title}</Typography></AccordionSummary>
                                <AccordionDetails style={{display: 'block'}}>
                                    <Typography>{step.description}</Typography>
                                    <Form ArrayFieldTemplate={ArrayFieldTemplate} ObjectFieldTemplate={ObjectFieldTemplate} schema={step.schema} formData={step.value} onChange={e => {
                                        updateAuthInfo(step.key, e.formData)
                                    }}><Fragment /></Form>
                                      <Button onClick={saveToDB} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
                                </AccordionDetails>
                            </Accordion>
                            )
                        }) :
                        null
                }
                {
                    authInfo.apiType == "customCode" ?
                    
                        customCodeConfig.steps.map((step, j) => {
                            const CodeBox = (props) => {
                                const [codestate, setCodeState] = React.useState()
                                const [textstate, setTextState] = React.useState(props.value)
                                console.log('it is a custom code thing!!')
                                console.log('props are: ', props)
                                return (
                                    props.schema.code === true ? <div style={{ height: '1200px' }}>
                                        <Typography>This is how the python code begins (can't edit):</Typography>
                                        <CodeMirror2
                                            value={starterCustomCode}
                                            options={{
                                                mode: 'python',
                                                theme: 'darcula',
                                                lineNumbers: true,
                                                lineWrapping: false
                                            }} />
                                        <br /><Typography>Enter Your Custom Code Here:</Typography>

                                        <CodeMirror2
                                            defaultValue={codestate ?? props.value ?? defaultCustomCode}
                                            value={codestate ?? props.value ?? defaultCustomCode}
                                            
                                            options={{
                                                mode: 'python',
                                                theme: 'darcula',
                                                lineNumbers: true,
                                                lineWrapping: false
                                            }}
                                            onChange={(editor, data, value) => {
                                            }}
                                            onBeforeChange={(editor, data, value) => {
                                                setCodeState(value); props.onChange(value)
                                            }} />
                                        <br /><Typography>This is how the code ends (can't edit):</Typography>
                                        <CodeMirror2
                                            value={enderCustomCode}
                                            options={{
                                                mode: 'python',
                                                theme: 'darcula',
                                                lineNumbers: true,
                                                lineWrapping: false
                                            }} />
                                    </div> :
                                        <TextField id="outlined-basic" formData={step.value} label={props.label} value={textstate ?? props.value} defaultValue={textstate ?? props.value} onChange={(event) => { setTextState(event.target.value); props.onChange(event.target.value) }} />

                                )
                            }

                            const widgets = {
                                TextWidget: CodeBox
                            };
                            return (
                                <Accordion onChange={handleStepAccordionChange(j)} style={{padding: '12px'}}>
                                 <AccordionSummary expandIcon={<ExpandMoreRounded />} onClick={() => setActiveStep(j)}><Chip size="small" color="primary" style={{marginRight: '10px'}} label={"Step "+(j+1)} /><Typography style={{fontWeight: 700}}>{step.title}</Typography></AccordionSummary>
                                    <AccordionDetails style={{display: 'block'}}>
                                        <Typography>{step.description}</Typography>

                                        <Form ArrayFieldTemplate={ArrayFieldTemplate} ObjectFieldTemplate={ObjectFieldTemplate} schema={step.schema} widgets={widgets} formData={step.value} onChange={e => {
                                            updateAuthInfo(step.key, e.formData);
                                            console.log('auth updated')

                                        }}><Fragment /></Form>
                                          <Button onClick={saveToDB} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
                                    </AccordionDetails>
                                </Accordion>

                            )
                        }) :
                        null
                }
                 <Accordion style={{padding: '12px'}}>
                        <AccordionSummary expandIcon={<ExpandMoreRounded />} onClick={console.log('clickedddd')}><Chip size="small" color="primary" style={{marginRight: '10px'}} label={"TEST"} /><Typography style={{fontWeight: 700}}>{"Test your Authentication"}</Typography></AccordionSummary>
                        <AccordionDetails style={{display: 'block'}}>
                            <Typography>{"Run your Test request using the account you connected to make sure everything is working."}</Typography>
                            <Authorizer app={appInfo} user={user} tenant={tenant} />
                              <Button onClick={saveToDB} size='large' color="primary" startIcon={saving === true ? <CircularProgress color="secondary" size={30} /> : <SaveRoundedIcon />} variant="contained" style={{float: 'right', textDecoration: 'none'}}>Save</Button>
                        </AccordionDetails>
                    </Accordion>
            </Stepper>
        </>
    )
}



export default () => {
    const classes = useStyles();

    const [allApps, setAllApps] = useState([])
    const [loadingAllApps, setLoadingAllApps] = useState(false)
    const [alert, setAlert] = useState();
    const [appInfo, setAppInfo] = useState({ actions: {}, triggers: {}, authInfo: {} })
    const [loadingApp, setLoadingApp] = useState(false)

    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [formType, setFormType] = useState(null)
    const [selectedObject, setSelectedObject] = useState(null)

    const statusOptions = [
        "Complete",
        "In Progress"
    ]

    const allAppsFilterOptions = createFilterOptions({
        matchFrom: 'any',
        limit: 50
    })

    console.log(appInfo)

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const loadApps = () => {
        setLoadingAllApps(true)
        axios({
            method: "get",
            url: "https://editor.workload.co:5000/api/apps"
        }).then(resp => { console.log(resp); setAllApps(resp.data); setLoadingAllApps(false) })
    }
    const pathname = window.location.pathname.split("/")[2]
    useEffect(() => {
        console.log('pathname is: ', pathname)
       if (pathname) {
        loadApp(pathname)
       }
    }, [pathname])

    const loadApp = (appSlug) => {
        setLoadingApp(true)
        setSelectedObject(null)
        setFormType(null)
        setAppInfo({ actions: {}, triggers: {}, authInfo: {} })
        axios({
            method: 'get',
            url: `https://editor.workload.co:5000/api/apps/`+pathname
        }).then(resp => { setAppInfo(resp.data); setLoadingApp(false); setDisabled(false) })
    }

    useEffect(() => {
        loadApps()
    }, [])


    const saveToDB = () => {
        console.log(appInfo)
        setSaving(true)
        axios({
            headers: {
                'content-type': 'application/json'
            },
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/' + appInfo.appSlug,
            data: JSON.stringify(appInfo)
        }).then(resp => { console.log(resp); setAlert({ "severity": "success", "message": appInfo.appName + " integration saved!" }); handleClick(); setSaving(false) })
    }

    const createAppDB = (newInfo) => {
        axios({
            method: 'post',
            url: 'https://editor.workload.co:5000/api/apps/',
            data: newInfo
        }).then(resp => { console.log(resp) })
    }

    const createApp = (appData) => {
        setAllApps([...allApps, appData])
        setAppInfo(appData)
        createAppDB(appData)
    }

    const makeNewKey = (dictOfKeys, testString, num = 0) => {
        if (Object.keys(dictOfKeys).includes(testString)) {
            return makeNewKey(dictOfKeys, testString.slice(0, testString.length - 1) + num, num + 1)
        } else {
            return testString
        }
    }

    const createAction = () => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        var newKey = makeNewKey(newAppInfo.actions, 'newAction0')
        var newAction = {
            key: newKey,
            fields: []
        }
        newAppInfo.actions[newKey] = newAction
        setFormType('action')
        setSelectedObject(newAppInfo.actions[newKey])
        setAppInfo(newAppInfo)
    }

    const updateAction = (newData) => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        console.log(newData)
        newAppInfo.actions[newData.key] = newData
        if (newData.key == selectedObject.key) {
            setSelectedObject(newAppInfo.actions[newData.key])
        } else {
            var oldObjKey = selectedObject.key
            setSelectedObject(newAppInfo.actions[newData.key])
            delete newAppInfo.actions[oldObjKey]
        }
        setAppInfo(newAppInfo)
        console.log(newAppInfo)
    }

    const deleteAction = (actionKey) => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        setFormType(null)
        setSelectedObject(null)
        delete newAppInfo.actions[actionKey]
        setAppInfo(newAppInfo)
    }

    const createTrigger = () => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        var newKey = makeNewKey(newAppInfo.triggers, 'newTrigger0')
        var newTrigger = {
            key: newKey,
            fields: [],
            pollingParams: {}
        }
        newAppInfo.triggers[newKey] = newTrigger
        setFormType('trigger')
        setSelectedObject(newAppInfo.triggers[newKey])
        setAppInfo(newAppInfo)
    }

    const updateTrigger = (newData) => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        console.log(newData)
        newAppInfo.triggers[newData.key] = newData
        if (newData.key == selectedObject.key) {
            console.log('new data trigger: ', newData)
            setSelectedObject(newAppInfo.triggers[newData.key])
        } else {
            var oldObjKey = selectedObject.key
            console.log('new data trigger: ', newData)
            setSelectedObject(newAppInfo.triggers[newData.key])
            delete newAppInfo.triggers[oldObjKey]
        }
        setAppInfo(newAppInfo)
        console.log(newAppInfo)
    }

    const deleteTrigger = (triggerKey) => {
        var newAppInfo = JSON.parse(JSON.stringify(appInfo))
        setFormType(null)
        setSelectedObject(null)
        delete newAppInfo.triggers[triggerKey]
        setAppInfo(newAppInfo)
    }

    const updateAuthInfo = (itemKey, itemValue) => {
        console.log(appInfo.authInfo)
        appInfo.authInfo[itemKey] = itemValue
    }
    const history = useHistory()
    console.log(appInfo)
    const [anchorElAuthMenu, setAnchorElAuthMenu] = React.useState(null);

    const handleAuthClick = (event) => {
      setAnchorElAuthMenu(event.currentTarget);
    };
  
    const handleCloseAuthMenu = () => {
      setAnchorElAuthMenu(null);
    };
    console.log('selected object is: ', selectedObject)

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <AppBarHeaderDummy />
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid style={{overflow: 'auto'}} item xs={4} >
                        <Paper className={classes.paper} style={{ marginLeft: 15 }}> <Typography ><h1>Apps</h1></Typography>
                            <Button variant='outlined' style={{marginBottom: '10px'}} color='primary' onClick={() => { history.push("/develop/new") }}>Add App</Button>
                            <Autocomplete
                                loading={loadingAllApps}
                                loadingText={"Loading Apps..."}
                                noOptionsText={"No Apps? Something has gone wrong..."}
                                options={allApps}
                                getOptionLabel={(option) => option.appName ?? option.appSlug}
                                onChange={(e, v) => {
                                    setAppInfo({ ...v, actions: {}, triggers: {}, authInfo: {} })
                                    if (v) {
                                        loadApp(v.appSlug);
                                        history.push("/develop/"+v.appSlug)

                                    } else {
                                        setDisabled(true)
                                    }
                                }}
                                value={appInfo ?? { appSlug: '' }}
                                renderInput={(params) => <TextField style={{ width: '100%', padding: '4px', margin: '4px' }} {...params} variant="outlined" placeholder={"Type to search apps"} label={"App"} />}
                                filterOptions={allAppsFilterOptions}
                            />
                            {
                                appInfo.appSlug ?
                                    <>
                                        <Autocomplete
                                            options={statusOptions}
                                            onChange={(e, v) => {
                                                setAppInfo({ ...appInfo, completionStatus: v })
                                            }}
                                            value={appInfo.completionStatus}
                                            renderInput={(params) => <TextField variant="outlined" style={{margin: '4px', width: '100%', padding: '4px' }} {...params} label={"Status"} />}
                                        />
                                        <TextField style={{margin: '4px', width: '100%', padding: '4px' }} label={"API Docs"} value={appInfo.apiDocs} onChange={(e) => { setAppInfo({ ...appInfo, apiDocs: e.target.value }) }} />
                                        <Box style={{width: '100%', height: '700px', overflow: 'scroll' }}>
                                        <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug)} style={{margin: '8px 0px 0px 0px'}}><SettingsRounded style={{marginRight: '10px', padding: '4px'}}/><Typography variant="h6">App Overview</Typography></MenuItem>
                                        <Accordion elevation={0} defaultExpanded>
                                            <AccordionSummary expandIcon={<ExpandMoreRounded />}><BuildRounded style={{marginRight: '10px', padding: '4px'}} /><Typography variant="h6">Develop</Typography></AccordionSummary>
                                            <AccordionDetails>
                                                <List>
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/authentication")} style={{margin: '0px 0px 4px 0px'}}>Authentication</MenuItem>
                                                    {appInfo.authInfo.apiType && (
                                                       <MenuItem style={{ color: '#0069ea', backgroundColor: '#f0f1fa'}}><VpnKeyRounded style={{marginRight: '8px'}} />{appInfo.authInfo.apiType === 'basic' &&
                                                       <Typography variant="body1">Basic Auth</Typography>}
                                                       {appInfo.authInfo.apiType === 'apikey' &&
                                                       <Typography variant="body1">API Key</Typography>}
                                                       {appInfo.authInfo.apiType === 'oauth2' &&
                                                       <Typography variant="body1">OAuth 2.0</Typography>}
                                                       {appInfo.authInfo.apiType === 'customCode' &&
                                                       <Typography variant="body1">Custom</Typography>}</MenuItem>  
                                                    )}
                                                    {appInfo.disableAuth === true && (
                                                       <MenuItem disabled><VpnKeyRounded style={{marginRight: '8px'}} /><Typography variant="body1">{"Passport App"}</Typography></MenuItem>  
                                                    )}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers")} style={{margin: '12px 0px 4px 0px'}}>Triggers</MenuItem>
                                                    {Object.values(appInfo.triggers).map(trigger => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/triggers/"+trigger.key+"/settings")}><OfflineBoltRounded style={{marginRight: '8px'}} /><Typography variant="body1">{trigger.label}</Typography></MenuItem> 
                                                    ))}
                                                    <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions")} style={{margin: '12px 0px 4px 0px'}}>Actions</MenuItem>
                                                    {Object.values(appInfo.actions).map(action => (
                                                       <MenuItem onClick={()=> history.push("/develop/"+appInfo.appSlug+"/actions/"+action.key+"/settings")}><SpellcheckRounded style={{marginRight: '8px'}} /><Typography variant="body1">{action.title}</Typography></MenuItem> 
                                                    ))}
                                                </List>
                                            </AccordionDetails>
                                        </Accordion>
                                        </Box>
                                    </> :
                                    null
                            }
                        </Paper>
                    </Grid>
                    
                    <Grid item xs={8} >
                    <Paper className={classes.paper}> 
                    <div style={{marginTop: '20px', marginBottom: '20px'}}>
                    {appInfo.authInfo.apiType === 'basic' &&
                            <Typography variant="h3">Basic Auth</Typography>}
                            {appInfo.authInfo.apiType === 'apikey' &&
                            <Typography variant="h3">API Key</Typography>}
                            {appInfo.authInfo.apiType === 'oauth2' &&
                            <Typography variant="h3">OAuth 2.0</Typography>}
                            {appInfo.authInfo.apiType === 'customCode' &&
                            <Typography variant="h3">Custom Auth</Typography>}
                            </div>
                    {appInfo.authInfo.apiType &&
                    <>
                    <Card style={{padding: '14px'}}>
                    {appInfo.authInfo.apiType === 'basic' && <>
                    <Typography variant="h5">What is Basic Auth?</Typography>
                    <p>Basic Auth includes a pre-built form where users enter a username and password to authenticate app accounts, 
                    which Workload then includes in every API call to that app. You only need to add a test API call to enable Basic Auth.</p></>}
                    {appInfo.authInfo.apiType === 'apikey' && <>
                    <Typography variant="h5">What is API Key Auth?</Typography>
                    <p>Use API Key authentication type if you simply need to collect some information from your users and then include that information, as it was entered by the user, when you make an API request.</p></>}
                    {appInfo.authInfo.apiType === 'oauth2' && <>
                    <Typography variant="h5">What is OAuth 2.0?</Typography>
                    <p>OAuth 2.0 authentication redirects users to your API’s site where they can authorize Workload to access their account which sends a request token to Workload that is exchanged for an access token.</p></>}
                    {appInfo.authInfo.apiType === 'customCode' && <>
                    <Typography variant="h5">What is Custom Auth?</Typography>
                    <p>Use the Custom authentication type if you need to collect some information from your users, for example a user name and password, and then make a request to your API to exchange that information for a token or session key, which you will use to authorize subsequent API requests.</p></>}
                    </Card>
                    </>
                    }
                     <AuthInfoForm authInfo={appInfo.authInfo} updateAuthInfo={updateAuthInfo} actions={appInfo.actions} appInfo={appInfo}  />
                    </Paper>
                    </Grid>
                     

                    
                    
                    
                    <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={alert ? alert.severity : null}>
                            {alert ? alert.message : null}
                        </Alert>
                    </Snackbar>
                    
                </Grid>
            </div></div>

    )
}
